import httpClient from './httpClient';

const url = `hotel`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  getBySlug(slug) {
    return httpClient.get(`${url}/getBySlug/${slug}`);
  },
  get() {
    return httpClient.get(url);
  },
};
