import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `tarifaTipo`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  get() {
    return httpClient.get(url);
  },
  publicSearch({ hotelId }) {
    return httpClient.get(`${url}/publicSearch?hotelId=${hotelId}`);
  },
  search({ hotelId, ativo }) {
    return httpAuthClient.get(
      `/${url}/search?hotelId=${hotelId}&ativo=${ativo}`,
    );
  },
  put(tarifaTipo) {
    return httpAuthClient.put(`/${url}`, tarifaTipo);
  },
  post(tarifaTipo) {
    return httpAuthClient.post(`/${url}`, tarifaTipo);
  },
  delete(id) {
    return httpAuthClient.delete(`/${url}/${id}`);
  },
  deleteAnexo(anexoId) {
    return httpAuthClient.delete(`/${url}/anexo/${anexoId}`);
  },
  postAnexos(pacoteId, anexos) {
    const formData = new FormData();

    anexos.forEach(anexo => {
      formData.append('files', anexo);
    });

    return httpAuthClient.post(`/${url}/anexos/${pacoteId}`, formData);
  },
  postBanner(pacoteId, banner) {
    const formData = new FormData();
    formData.append('file', banner);

    return httpAuthClient.post(`/${url}/banner/${pacoteId}`, formData);
  },
};
