<template>
  <section class="section" id="gerenciar-ingressos">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-grey">
            Gerenciamento >
            <router-link to="/admin/gerenciar-tarifas" class="has-text-grey">
              Tarifas
            </router-link>
            >
            <span class="has-text-primary">
              Tipos de Tarifas
            </span>
          </h3>
        </div>

        <div class="column is-1" style="min-width: fit-content">
          <router-link
            to="/admin/cadastrar-tarifa-tipo"
            class="button is-primary is-fullwidth"
          >
            <b-icon icon="plus" class="mr-2" />
            Cadastrar Tipo de Tarifa
          </router-link>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <b-field label="Hotel">
            <b-select v-model="filtro.hotelId" expanded>
              <option v-for="hotel in hoteis" :key="hotel.id" :value="hotel.id">
                {{ hotel.nome }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column">
          <b-field label="Ativo">
            <b-radio-button v-model="filtro.ativo" :native-value="1" expanded>
              Sim
            </b-radio-button>
            <b-radio-button v-model="filtro.ativo" :native-value="0" expanded>
              Não
            </b-radio-button>
          </b-field>
        </div>
        <div class="column" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded @click="getTipos">
            Buscar
          </b-button>
        </div>
      </div>
      <section
        v-if="tarifasTipos.length === 0 && !isLoading"
        class="section has-background-light has-text-centered"
        style="border-radius: 10px"
      >
        Nenhum resultado encontrado para a sua busca.
      </section>

      <b-table
        v-else
        :data="tarifasTipos"
        :loading="isLoading"
        hoverable
        mobile-cards
      >
        <b-table-column
          field="id"
          label="ID"
          width="100"
          numeric
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          field="maxPessoa"
          label="Max. Pessoa"
          numeric
          centered
          sortable
          v-slot="props"
        >
          {{ props.row.maxPessoa }}
        </b-table-column>
        <b-table-column
          field="nome"
          label="Nome"
          centered
          sortable
          v-slot="props"
        >
          {{ props.row.nome }}
        </b-table-column>
        <b-table-column field="acoes" label="Ações" v-slot="props" centered>
          <b-button
            type="is-secondary"
            size="is-small"
            class="mr-3"
            icon-left="pencil"
            @click="editTarifaTipo(props.row)"
          />
          <b-button
            type="is-danger"
            size="is-small"
            icon-left="delete"
            @click="deleteTarifaTipo(props.row)"
          />
        </b-table-column>
      </b-table>
    </div>
    <ModalConfirmar
      :ativo="isModalConfirmDeleteActive"
      @cancel="cancelDeleteTarifaTipo"
      @confirm="confirmDeleteTarifaTipo"
    />
  </section>
</template>

<script>
import ModalConfirmar from '@/components/ModalConfirmar.vue';
import tarifaTipoService from '@/services/tarifaTipoService';
import hoteisService from '@/services/hoteisService';

export default {
  components: { ModalConfirmar },
  name: 'GerenciarTarifaTipo',
  data() {
    return {
      isLoading: false,
      isModalConfirmDeleteActive: false,
      filtro: {
        hotelId: null,
        ativo: 1,
      },
      hoteis: [],
      tarifasTipos: [],
      trashTarifaTipo: null,
    };
  },
  created() {
    this.getHoteis().then(() => {
      this.getTipos();
    });
  },
  methods: {
    async getHoteis() {
      const { data } = await hoteisService.get();
      this.hoteis = data;
      const [hotel] = data;
      if (hotel) {
        this.filtro.hotelId = hotel.id;
      }
    },
    getTipos() {
      if (this.filtro.hotelId) {
        this.isLoading = true;

        tarifaTipoService
          .search(this.filtro)
          .then(({ data }) => {
            this.tarifasTipos = data;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    editTarifaTipo({ id }) {
      this.$router.push(`/admin/editar-tarifa-tipo/${id}`);
    },
    deleteTarifaTipo(tarifaTipo) {
      this.trashTarifaTipo = tarifaTipo;
      this.isModalConfirmDeleteActive = true;
    },
    cancelDeleteTarifaTipo() {
      this.trashTarifaTipo = null;
      this.isModalConfirmDeleteActive = false;
    },
    confirmDeleteTarifaTipo() {
      tarifaTipoService
        .delete(this.trashTarifaTipo.id)
        .then(() => {
          this.$buefy.snackbar.open(`Tipo de tarifa excluído com sucesso!`);
          this.getTipos();
          this.trashTarifaTipo = null;
          this.isModalConfirmDeleteActive = false;
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Erro ao excluir tipo de tarifa',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        });
    },
  },
};
</script>
